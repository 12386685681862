<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="d-flex flex-column">
    <div class="font-bold text-lg text-black mt-1 text-center">
      Destination
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATDestination"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            width="250px"
            debounce="1000"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <div class="font-bold text-lg text-black mt-1 text-center mt-3">
      Calculate Shipping
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATCalculate"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            width="250px"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <div class="font-bold text-lg text-black mt-1 text-center mt-3">
      Order - Create Orders
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATCreateOrder"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <div class="font-bold text-lg text-black mt-1 text-center mt-3">
      Order - Order Detail
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATOrderDetail"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <div class="font-bold text-lg text-black mt-1 text-center mt-3">
      Order - Cancel Order
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATCancelOrder"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <div class="font-bold text-lg text-black mt-1 text-center mt-3">
      Pickup
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATPickup"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <div class="font-bold text-lg text-black mt-1 text-center mt-3">
      Print Label
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATPrint"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <div class="font-bold text-lg text-black mt-1 text-center mt-3">
      History AWB
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listUATHistoryAWb"
        :fields="fieldUAT"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
            v-html="data.item.name"
          />
        </template>
        <template #cell(document)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column font-italic"
          >
            {{ data.item.input }}
          </div>
        </template>
        <template #cell(expect_result)="data">
          <div
            v-if="data.item.expected_result"
            class="d-flex flex-column align-items-center gap-5 code"
            v-html="data.item.expected_result"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #cell(curl)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.curl }}
          </div>
        </template>
        <template #cell(result)="data">
          <div
            class="border rounded p-2 preview-result"
          >
            {{ data.item.result }}
          </div>
        </template>
        <template #cell(note)="data">
          <b-form-textarea
            id="textarea-no-resize"
            v-model="data.item.notes"
            placeholder="Notes"
            rows="8"
            max-rows="8"
            @input="updateNote(data.item.uat_id, $event)"
          />
        </template>
      </BTable>
    </BOverlay>
    <hr>
    <div class="d-flex justify-content-end gap-5">
      <b-button
        variant="light"
        size="md"
        @click="rejectRequest()"
      >
        Reject
      </b-button>
      <b-button
        variant="primary"
        size="md"
        @click="approveRequest()"
      >Approve</b-button>
    </div>
    <ModalReject
      :get-uat="getUAT"
      :get-detail="getDetail"
      :document-type="docType"
      :params-uat="paramsUAT"
    />
    <ModalApprove
      :get-uat="getUAT"
      :get-detail="getDetail"
      :document-type="docType"
      :params-uat="paramsUAT"
    />
  </div>
</template>

<script>
import { openApiAxiosIns } from '@/libs/axios'
import { fieldUAT } from '../config'
import ModalReject from './ModalRejectRequest.vue'
import ModalApprove from './ModalApproveRequest.vue'

export default {
  components: {
    ModalReject,
    ModalApprove,
  },
  props: {
    getDetail: {
      type: Function,
      default: () => { },
      required: true,
    },
  },
  data() {
    return {
      fieldUAT,
      listUATDestination: [],
      listUATCalculate: [],
      listUATCreateOrder: [],
      listUATOrderDetail: [],
      listUATCancelOrder: [],
      listUATPickup: [],
      listUATPrint: [],
      listUATHistoryAWb: [],
      paramsUAT: [],
      userId: null,
      docType: '',
      loading: false,
    }
  },
  mounted() {
    const { id } = this.$router.history.current.params
    this.userId = id
    this.getUAT()
  },
  methods: {
    rejectRequest() {
      this.docType = 'UAT'
      this.$bvModal.show('modal-reject-request')
    },
    approveRequest() {
      this.docType = 'UAT'
      this.$bvModal.show('modal-approve-request')
    },
    async getUAT() {
      this.loading = true
      const url = `/user/api/v1/admin/document-request/user-acceptance-test/${this.userId}`

      try {
        const res = await openApiAxiosIns.get(url)
        const { data } = res.data

        const categories = {
          listUATDestination: [1],
          listUATCalculate: [2, 3, 4, 5],
          listUATCreateOrder: [6, 7, 8, 9, 10, 11, 12, 13],
          listUATOrderDetail: [14],
          listUATCancelOrder: [15, 16],
          listUATPickup: [17, 18],
          listUATPrint: [19],
          listUATHistoryAWb: id => id > 19,
        }

        Object.keys(categories).forEach(key => {
          const filter = categories[key]
          this[key] = typeof filter === 'function'
            ? data.filter(item => filter(item.test_case_id))
            : data.filter(item => filter.includes(item.test_case_id))
        })
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data' })
      } finally {
        this.loading = false
      }
    },
    updateNote(uatId, notes) {
      const existingItem = this.paramsUAT.find(item => item.uat_id === uatId)

      if (existingItem) {
        existingItem.notes = notes
      } else {
        this.paramsUAT.push({ uat_id: uatId, notes })
      }
    },

  },
}
</script>
<style scoped>
  div::-webkit-scrollbar {
    display: none;
  }
  .preview-result {
    max-width: 200px;
    min-width: 100px;
    height: 250px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .code {
    font-family: 'Fira Code', monospace;
  }
</style>
