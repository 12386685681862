<template>
  <BCard>
    <div class="text-black text-[20px] mb-2 font-[600] d-flex align-items-center">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
        alt="Komerce"
        class="cursor-pointer mx-1"
        @click="$router.back()"
      >
      Detail Document Request Collaborator
    </div>
    <div class="border rounded mt-3 px-1 px-lg-4 py-2">
      <div class="d-flex justify-content-between">
        <h5 class="text-black">
          Detail Data
        </h5>
        <div
          :class="submitted"
          class="rounded text-center px-[10px] d-flex align-items-center justify-content-center gap-2 text-capitalize"
        >
          <img
            :src="imgStatus"
            alt="info"
            width="15px"
          >
          {{ submitted }}
        </div>
      </div>
      <div class="d-flex mt-1">
        <span class="col-3 px-0">Full Name/Company</span>
        <span class="col">: {{ dataDetail.name || '-' }}</span>
      </div>
      <div class="d-flex mt-1">
        <span class="col-3 px-0">Email</span>
        <span class="col">: {{ dataDetail.email || '-' }}</span>
      </div>
      <div class="d-flex mt-1">
        <span class="col-3 px-0">Phone Number</span>
        <span class="col">: {{ dataDetail.contact || '-' }}</span>
      </div>
      <div class="d-flex mt-1 align-items-start">
        <span class="col-3 px-0">Address</span>
        <span class="col d-flex">
          <span class="mr-[5px]">:</span>
          <span>
            {{ dataDetail.address || '-' }}
          </span>
        </span>
      </div>
      <div class="d-flex mt-1">
        <span class="col-3 px-0">Document</span>
        <span class="col d-flex">
          <span class="mr-[5px]">:</span>
          <div class="d-flex gap-5 col px-0 flex-wrap">
            <span class="d-flex border rounded w-[80px] px-1 align-items-center justify-content-between h-[30px] gap-3">
              <span>SLA</span>
              <img
                :src="statusIcon(dataDetail.document_sla_status)"
                alt="status"
                width="20px"
              >
            </span>
            <span class="d-flex border rounded w-[80px] px-1 align-items-center justify-content-between h-[30px] gap-3">
              <span>CA</span>
              <img
                :src="statusIcon(dataDetail.document_ca_status)"
                alt="status"
                width="20px"
              >
            </span>
            <span class="d-flex border rounded w-[80px] px-1 align-items-center justify-content-between h-[30px] gap-3">
              <span>UAT</span>
              <img
                :src="statusIcon(dataDetail.uat_status)"
                alt="status"
                width="20px"
              >
            </span>
          </div>
        </span>
      </div>
    </div>
    <div class="mt-3 d-flex flex-column flex-md-row gap-5">
      <b-button
        v-for="(button, index) in buttonTabs"
        :key="index"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="tabPosition === index + 1 ? 'primary' : 'outline-secondary'"
        size="md"
        pill
        @click="switchTab(button.tabIndex)"
      >
        {{ button.label }}
      </b-button>
    </div>
    <div class="py-2">
      <SLATab
        v-if="tabPosition === 1"
        :get-detail="getDetail"
      />
      <CATab
        v-if="tabPosition === 2"
        :get-detail="getDetail"
      />
      <UATTab
        v-if="tabPosition === 3"
        :get-detail="getDetail"
      />
    </div>
  </BCard>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { openApiAxiosIns } from '@/libs/axios'
import SLATab from '../components/SLATab.vue'
import CATab from '../components/CATab.vue'
import UATTab from '../components/UATTab.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    SLATab,
    CATab,
    UATTab,
  },
  data() {
    return {
      loading: false,
      tabPosition: 1,
      buttonTabs: [
        {
          label: 'Service Level Agreement',
          tabIndex: 1,
        },
        {
          label: 'Collaboration Agreement',
          tabIndex: 2,
        },
        {
          label: 'User Acceptance Test',
          tabIndex: 3,
        },
      ],
      userId: null,
      dataDetail: [],
    }
  },
  computed: {
    submitted() {
      if (this.dataDetail.document_sla_status === 6 || this.dataDetail.document_ca_status === 6 || this.dataDetail.uat_status === 6) {
        return 'rejected'
      }
      if (this.dataDetail.document_sla_status === 5 && this.dataDetail.document_ca_status === 5 && this.dataDetail.uat_status === 5) {
        return 'approved'
      }
      if (this.dataDetail.document_sla_status >= 4 || this.dataDetail.document_ca_status >= 4 || this.dataDetail.uat_status >= 4) {
        return 'submitted'
      }
      return ''
    },
    imgStatus() {
      if (this.dataDetail.document_sla_status === 6 || this.dataDetail.document_ca_status === 6 || this.dataDetail.uat_status === 6) {
        return 'https://storage.googleapis.com/komerce/assets/svg/danger-information-circle!.svg'
      }
      if (this.dataDetail.document_sla_status === 5 && this.dataDetail.document_ca_status === 5 && this.dataDetail.uat_status === 5) {
        return 'https://storage.googleapis.com/komerce/assets/svg/tick-circle.svg'
      }
      if (this.dataDetail.document_sla_status >= 4 || this.dataDetail.document_ca_status >= 4 || this.dataDetail.uat_status >= 4) {
        return 'https://storage.googleapis.com/komerce/assets/email/info-circle!.png'
      }
      return ''
    },
  },
  mounted() {
    const { id } = this.$router.history.current.params
    this.userId = id
    this.getDetail()
  },
  methods: {
    switchTab(v) {
      this.tabPosition = v
    },
    async getDetail() {
      this.loading = true

      const url = `/user/api/v1/admin/document-request/${this.userId}`
      await openApiAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.dataDetail = data
        })
        .catch(() => {
          this.loading = false
        })
    },
    statusIcon(v) {
      if (v <= 4) return 'https://storage.googleapis.com/komerce/icon/circle-minus-gray.svg'
      if (v === 5) return 'https://storage.googleapis.com/komerce/assets/icons/Checklist-fill.svg'
      if (v === 6) return 'https://storage.googleapis.com/komerce/assets/LP-Komads/Symbol%20close.svg'
      return ''
    },
  },
}
</script>
<style scoped>
.submitted {
  background-color: #fffbe6;
  border: 1px solid #faad15;
  color: #faad15;
}
.approved {
  background-color: #eaffe6;
  border: 1px solid #32a46e;
  color: #32a46e;
}
.rejected {
  background-color: #ffe6e6;
  border: 1px solid #d22120;
  color: #d22120;
}
</style>
