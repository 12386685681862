<template>
  <BModal
    id="modal-reject-request"
    ref="modal-reject-request"
    hide-footer
    size="md"
    hide-header
    centered
    hide-header-close
  >
    <div class="p-[32px]">
      <div class="text-[#333333] text-center font-bold text-[20px]">
        Want to reject the document submission?
      </div>
      <div class="text-[#626262] text-center leading-[24px] text-[16px] mt-1">
        Please provide detailed information about the reason for the rejection so that the collaborator can resubmit acceptable documents.
      </div>
      <div class="mt-1">
        <b-form-textarea
          id="textarea"
          v-model="notes"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        />
        <div class="d-flex justify-content-between">
          <small
            v-if="notes.length < 10"
            class="text-danger"
          >Must have at least 10 characters.</small>
          <small
            v-else-if="notes.length > 250"
            class="text-danger"
          >Must not exceed 250 characters.</small>
          <span>{{ notes.length }}/250</span>
        </div>
      </div>
      <div class="flex gap-[10px] mt-[32px]">
        <BButton
          variant="primary"
          class="w-full"
          @click="$bvModal.hide('modal-reject-request')"
        >
          Cancel
        </BButton>
        <BButton
          :variant="notes.length < 10 || notes.length > 250 ? 'outline-secondary' : 'outline-primary'"
          class="w-full"
          :disabled="notes.length < 10 || notes.length > 250"
          @click="handleReject()"
        >
          Reject
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { openApiAxiosIns } from '@/libs/axios'

export default {
  props: {
    getDetail: {
      type: Function,
      required: true,
    },
    documentType: {
      type: String,
      default: '',
    },
    paramsUat: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      notes: '',
      userId: this.$router.history.current.params.id,
    }
  },
  methods: {
    handleReject() {
      if (this.documentType === 'UAT') {
        this.updateUat()
      } else {
        this.rejectReq()
      }
    },
    async rejectReq() {
      const url = `/user/api/v1/admin/user-document-state/${this.userId}`
      const params = { document_type: this.documentType, state: 6, notes: this.notes }

      try {
        await openApiAxiosIns.put(url, params)
        this.$toast_success({ message: 'Request Rejected' })
        this.getDetail()
        this.$bvModal.hide('modal-reject-request')
      } catch {
        this.$toast_error({ message: 'Failed to reject the request' })
      }
    },
    async updateUat() {
      const params = {
        input: this.paramsUat,
      }
      const url = '/user/api/v1/admin/document-request/user-acceptance-test'
      await openApiAxiosIns.put(`${url}/${this.userId}`, params)
        .then(res => {
          this.rejectReq()
        })
        .catch(() => {
          this.$toast_error({ message: 'Failed update UAT' })
        })
    },
  },
}
</script>
